import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "社内勉強会 TechLunch で ECMAScript についての発表をしました",
  "date": "2017-04-11T10:04:13.000Z",
  "slug": "entry/2017/04/11/190413",
  "tags": ["medley"],
  "hero": "./2017_04_11.png",
  "heroAlt": "ecma"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部エンジニア平木です。`}</p>
    <p>{`弊社では定例で TechLunch という社内勉強会を開いています。今回は自分が担当になったので、最近の動向も含めて`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`について話をしました。`}</p>
    <h1>{`なぜ`}<a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`について話そうと思ったのか?`}</h1>
    <p>{`ご存知の方も多いでしょうが、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`とは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/JavaScript"
      }}>{`JavaScript`}</a>{`の仕様になります。`}</p>
    <p>{`ここからは個人的な印象になりますが、つい数年前までは特にフロントエンド開発をする人でも仕様のことを意識しなくてもあまり問題はなかったと思います。`}</p>
    <p>{`しかし、Babel や TypeScript などが普及しだしてきたあたりから、ブラウザの実装に関係なく`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`で提案されている仕様が使えるようになり、段々と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`について知っておいた方が開発効率が上がる…ということになってきたかと思います。`}</p>
    <p>{`最近ですと、特に Babel を使う前提の React.js などが一般に使われてきていますので、そういった開発をするときに`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`の仕様がどのように決まって次の仕様にはどういった機能が取り込まれるのかを知っておくと開発がしやすくなるのではないかと考えています。`}</p>
    <p>{`弊社でもちらほらと、ECMAScript2015 以降を使うようになってきているという事情もあり、ここらで情報が追いやすくなってきている`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`について興味を持ってもらおうという意図で題材に選びました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/L/Layzie/20170303/20170303120548.jpg",
        "alt": "f:id:Layzie:20170303120548j:plain"
      }}></img></p>
    <h1>{`話す内容で考えたこと`}</h1>
    <p>{`このような思いから、題材は決まったのですが、いきなり`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`について話しだしても全然わからないよねということになります。`}</p>
    <p>{`以前に`}<a parentName="p" {...{
        "href": "https://meguroes.connpass.com/event/21510/"
      }}>{`Meguro.es #1`}</a>{`というイベントで`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`について`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/layzie/ecma262"
      }}>{`LT をさせてもらった`}</a>{`のですが、イベントの性質上前提をすっ飛ばして話した感じでした。`}</p>
    <p>{`しかし、弊社のエンジニア陣は今でこそフロントエンドも実装していますが元々はバックエンドなどフロントエンド以外出身のエンジニアが多いので、本題のなぜ`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`の次の仕様が追いやすくなったしキャッチアップしていこう!という点がピンとこない可能性があります。`}</p>
    <p>{`前提としてそもそも`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/JavaScript"
      }}>{`JavaScript`}</a>{`の歴史や、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`が策定される経緯などから話さないといけないかも…と色々盛り込んだ話をしたのですが、結果としてはちょっと本題がボヤけてしまった感じがあり反省しています…。`}</p>
    <p>{`スライドはこちらになります。`}</p>
    <h1>{`スライドには入れられなかった部分`}</h1>
    <p>{`時間の都合で話したかったけど入っていないことを 2 点ほど。`}</p>
    <h2>{`Babel について`}</h2>
    <p>{`TechLunch の発表では、構成上入れていなかったのですが、弊社のプロダクトでいうと`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/JavaScript"
      }}>{`JavaScript`}</a>{`を結局は`}<a parentName="p" {...{
        "href": "https://babeljs.io/"
      }}>{`Babel`}</a>{`を使ってトランスパイルするという形がほとんどです。`}</p>
    <p>{`基本の ES2015 の機能については`}<a parentName="p" {...{
        "href": "https://babeljs.io/learn-es2015/"
      }}>{`Learn ES2015 · Babel`}</a>{`や`}<a parentName="p" {...{
        "href": "https://github.com/yosuke-furukawa/tower-of-babel"
      }}>{`yosuke-furukawa/tower-of-babel`}</a>{`なんかで覚えることができると思います。`}</p>
    <p>{`しかし、極端な例でいうと Babel の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D7%A5%E9%A5%B0%A5%A4%A5%F3"
      }}>{`プラグイン`}</a>{`の`}<a parentName="p" {...{
        "href": "https://babeljs.io/docs/plugins/preset-stage-0/"
      }}>{`Stage 0 preset · Babel`}</a>{`を使ったりするとさらに便利(かもしれない)機能が使えるようになります。この`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D7%A5%E9%A5%B0%A5%A4%A5%F3"
      }}>{`プラグイン`}</a>{`で使える機能は何か?というのを調べるとなると、やはり`}<a parentName="p" {...{
        "href": "https://github.com/tc39/proposals"
      }}>{`tc39/proposals`}</a>{`などを見たほうが早いということになります。`}</p>
    <p>{`次にどんな機能が来るなどの情報はこの発表の内容などを知ってるとキャッチアップしやすくなると思います。`}</p>
    <h2>{`詳しい情報源について+α`}</h2>
    <p>{`参考情報として載せたなかで個人的に `}<strong parentName="p">{`早い・分かりやすい・詳しい`}</strong>{` を兼ねそなえているのが `}<em parentName="p">{`Dr. Axel Rauschmayer`}</em>{` の `}<a parentName="p" {...{
        "href": "https://2ality.com/index.html"
      }}>{`2ality – JavaScript and more`}</a>{`かなと思っています。`}</p>
    <p>{`英語が苦でなければこの人のブログを読んでるだけで実用上問題ないくらいに`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`の動向についていけます。`}</p>
    <p>{`この方の`}<a parentName="p" {...{
        "href": "https://exploringjs.com/"
      }}>{`JavaScript / ECMAScript 関連の本`}</a>{`もすばらしいので、これまた英語が苦でなければぜひ読んでください。`}</p>
    <p>{`ちなみにこの方は`}<a parentName="p" {...{
        "href": "https://esnextnews.com/"
      }}>{`ES.next News: 5 ECMAScript.next links, every week`}</a>{`という`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A1%BC%A5%EA%A5%F3%A5%B0%A5%EA%A5%B9%A5%C8"
      }}>{`メーリングリスト`}</a>{`も運営しておりこちらは粒度としては 1 週間に 1 回になるんである意味読みやすい情報かもしれません。`}</p>
    <p>{`日本語ですと、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`だけではないですがやっぱり`}<a parentName="p" {...{
        "href": "https://twitter.com/azu_re"
      }}>{`azu`}</a>{`さんの`}<a parentName="p" {...{
        "href": "https://jser.info/"
      }}>{`JSer.info`}</a>{`がよいかと思います。`}</p>
    <h1>{`まとめ`}</h1>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`についての勉強会を開催したお話でした。メドレーでは、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ECMAScript"
      }}>{`ECMAScript`}</a>{`の仕様読むの大好物!というような方もぜひ来ていただきたいと思っています!!`}</p>
    <p>{`また 4/19(水)に筆者が携わっている CLINICS について話すイベントを開催します。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MedNight Tokyo #2 〜Web 技術でレガシーな医療業界に革命を！エンジニア開発裏話 (2017/04/19 20:00〜)" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fmednight.connpass.com%2Fevent%2F50788%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://mednight.connpass.com/event/50788/">mednight.connpass.com</a></cite>
    <p>{`フロントエンドのことについて話す予定ですので、ご興味あればぜひご参加ください!`}</p>
    <h3>{`お知らせ`}</h3>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      